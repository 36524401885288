type SVGProps = React.SVGProps<SVGSVGElement>

export const Logo = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" {...props}>
    <path
      fill="currentColor"
      d="M700,255.4c-36.19,47.95-123.15,63.93-123.15,63.93,99.18-41.83,118.28-189.17,118.28-189.17-63.93,143.37-193.05,149.49-193.05,149.49,185.06-69.57,161.5-244.44,161.5-244.44,4.85,138.32-218.21,202.69-218.21,202.69,2.57-4.44,12.47-14.64,12.47-14.64-58.79,39.76-58.8,59.4-70.31,83.88-11.51,24.48-28.29,76.62-29.09,100.22-.31,9.22,1.57,18.39,5.68,26.65,4.56,9.16,9.65,23.97,1.73,34.06-14.75,18.77-15.83,35.12-15.83,35.12,0,0-1.08-16.35-15.83-35.12-7.93-10.08-2.83-24.9,1.73-34.06,4.11-8.26,5.99-17.43,5.68-26.65-.8-23.6-17.57-75.74-29.09-100.22-11.51-24.47-11.52-44.12-70.31-83.88,0,0,9.9,10.2,12.47,14.64,0,0-223.06-64.37-218.21-202.69,0,0-23.56,174.87,161.5,244.44,0,0-129.13-6.13-193.05-149.49,0,0,19.1,147.33,118.28,189.17,0,0-86.96-15.98-123.15-63.93,0,0,102.12,161.76,243.04,152.88,0,0,17.58,92.71-44.76,167.83,0,0,83.75-38.69,96.44-109.19,0,0,7.99,41.36,18.33,53.59,6.6,7.8,15.69,34.95,21.41,53.57,3.62,11.81,6.52,23.83,8.66,36,2.97,16.85,6.88,41.56,6.88,54.71,0-13.15,3.91-37.86,6.88-54.71,2.14-12.17,5.03-24.19,8.66-36,5.71-18.62,14.8-45.77,21.41-53.57,10.34-12.22,18.33-53.59,18.33-53.59,12.69,70.51,96.44,109.19,96.44,109.19-62.34-75.13-44.76-167.83-44.76-167.83,140.92,8.88,243.04-152.88,243.04-152.88Z"
    />
  </svg>
)

export const Close = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <line x1="13" y1="3" x2="3" y2="13" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
    <line x1="3.2" y1="2.8" x2="12.8" y2="13.2" stroke="currentColor" strokeLinecap="round" strokeWidth="2" />
  </svg>
)

export const Starknet = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" {...props}>
    <path d="M20.5,20.9,17,31.4a1.1,1.1,0,0,1-1.5.5,1.2,1.2,0,0,1-.5-.5L11.5,20.9a.8.8,0,0,0-.4-.4L.6,17a1.1,1.1,0,0,1-.5-1.5L.6,15l10.5-3.5a.8.8,0,0,0,.4-.4L15,.6A1.1,1.1,0,0,1,16.5.1l.5.5,3.5,10.5.4.4L31.4,15a1.1,1.1,0,0,1,.5,1.5,1.2,1.2,0,0,1-.5.5L20.9,20.5Z" />
  </svg>
)

export const CarretRight = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M5.6,23.4L5.6,23.4c-0.8-0.8-0.8-2,0-2.8c0,0,0,0,0,0l8.7-8.6L5.6,3.4c-0.8-0.8-0.8-2,0-2.8c0,0,0,0,0,0l0,0
	c0.8-0.8,2-0.8,2.8,0l10.1,10c0.8,0.8,0.8,2,0,2.8c0,0,0,0,0,0l-10.1,10C7.6,24.2,6.4,24.2,5.6,23.4z"
    />
  </svg>
)

export const Checkmark = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" {...props}>
    <polyline
      fill="none"
      stroke="currentColor"
      points="12.9,5.7 6.4,12.3 3.1,9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export const Jediswap = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" fill="currentColor" {...props}>
    <polygon points="297 252 282 278 27 278 0 328 360 328 297 252" />
    <polygon points="63 108 78 82 333 82 360 32 0 32 63 108" />
    <polygon points="225.5 205 105.5 205 134.5 155 254.5 155 225.5 205" />
  </svg>
)

export const Ekubo = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" fill="currentColor" {...props}>
    <path
      d="M30.6,6.4H5.4c-3,0-5.4,2.4-5.4,5.4v12.5c0,3,2.4,5.4,5.4,5.4h25.2c3,0,5.4-2.4,5.4-5.4V11.7C36,8.8,33.6,6.4,30.6,6.4z
	 M25.2,25.2c-4,0-7.2-3.2-7.2-7.2c0,4-3.2,7.2-7.2,7.2c-4,0-7.2-3.2-7.2-7.2s3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2
	c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2S29.2,25.2,25.2,25.2z"
    />
  </svg>
)

export const StarkDeFi = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154 154" fill="currentColor" {...props}>
    <path d="M131.45,22.55c-30.07-30.07-78.83-30.07-108.9,0-30.07,30.07-30.07,78.83,0,108.9,30.07,30.07,78.83,30.07,108.9,0s30.07-78.83,0-108.9ZM115.91,84.05l-17.32,17.32,14.85,14.85-14.85,14.85-21.92-21.92c-4.3-4.3-4.3-11.26,0-15.56l24.4-24.39c1.07-1.08,1.61-2.48,1.61-3.89s-.54-2.81-1.61-3.89c-2.15-2.15-5.63-2.15-7.78,0l-20.86,20.86c-5.17,5.18-11.96,7.76-18.74,7.76s-13.57-2.58-18.74-7.76c-10.74-10.74-10.74-28.15,0-38.89l2.83-2.83,14.85,14.85-3.18,3.18c-2.35,2.34-2.35,6.14,0,8.49,2.34,2.34,6.14,2.34,8.48,0l20.51-20.51c5.18-5.17,11.96-7.76,18.74-7.76s13.56,2.59,18.73,7.76c10.35,10.35,10.35,27.13,0,37.48Z" />
  </svg>
)

export const ETH = (props: SVGProps) => (
  <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="currentColor" {...props}>
    <path d="M103.29,65.2L64.02,0h0L24.71,65.2l39.31,23.22h0l39.28-23.22ZM60.94,83.04l-32-18.92L60.94,11.06v71.98Z" />
    <path d="M103.29,72.63l-39.28,23.22-39.31-23.22,39.31,55.37M60.94,118.39l-25.4-35.78,25.4,14.99v20.79Z" />
  </svg>
)

export const STRK = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="currentColor" {...props}>
    <circle cx="97.24" cy="91.77" r="7.25" />
    <path d="M110.77,47.91c-.17.1-.34.2-.5.31-1.09.66-2.07,1.39-2.97,2.14-1.82,1.53-3.36,3.12-4.82,4.79-2.9,3.28-5.39,6.85-7.93,10.43-1.29,1.71-2.48,3.66-3.78,5.39l-1.88,2.78c-.68.97-1.18,1.67-2.12,2.91-3.41,4.49-7.44,8.81-12.42,12.6-4.98,3.75-11.13,7-18.14,8.35-7,1.41-14.47.47-20.43-2.13-4.6-1.93-8.42-4.64-11.65-7.7.76.48,1.58.97,2.47,1.42,1.73.85,3.49,1.54,5.26,2.04,2.63.72,5.37,1.09,8.07,1.09,1.31,0,2.62-.09,3.9-.27,3.8-.51,7.68-1.71,11.21-3.49,3.01-1.5,5.9-3.39,8.84-5.8,4.48-3.72,8.53-8.16,12.36-13.57,1.56-2.18,3.06-4.51,4.58-7.1l1.59-2.75c.49-.81.97-1.59,1.45-2.31,2.15-3.23,4-5.35,6.03-6.87,2.33-1.84,5.38-2.91,9.06-3.18,3.36-.25,7.24.06,11.82.93Z" />
    <circle cx="97.24" cy="91.77" r="7.25" />
    <path d="M102.4,33.1c-4.04-2.54-9.27-4.12-14.76-4.12-1.78,0-3.59.16-5.39.52-3.64.7-7.16,2.06-10.26,3.86-3.08,1.8-5.84,3.96-8.24,6.28-1.19,1.17-2.27,2.38-3.35,3.61l-2.79,3.55-4.31,5.72c-5.49,7.37-11.41,16-21.11,18.55-3.22.85-5.82,1.16-8.08,1.16-3.49,0-6.17-.74-9.04-1.34,1.01,2.42,2.14,4.42,3.42,6.09,1.84,2.51,3.79,4.52,6,6.17,1.07.76,2.4,1.67,3.9,2.42,1.5.74,3.03,1.34,4.54,1.76,2.26.61,4.68.93,7.04.93,1.12,0,2.23-.07,3.31-.22,3.37-.45,6.8-1.52,9.94-3.11,2.75-1.36,5.39-3.09,8.08-5.29,4.2-3.49,8.01-7.68,11.64-12.8,1.49-2.09,2.92-4.32,4.38-6.8l1.61-2.77c.53-.89,1.04-1.71,1.55-2.49,2.42-3.62,4.56-6.04,6.92-7.83,2.95-2.32,6.73-3.68,11.24-4,.85-.06,1.74-.1,2.65-.1,3.41,0,7.24.45,11.61,1.35-2.29-4.45-5.9-8.23-10.51-11.13ZM101.31,38.85c-1.01,0-2,.03-2.93.1-5.3.38-9.81,2-13.42,4.83-2.72,2.07-5.13,4.78-7.82,8.8-.49.75-1.01,1.59-1.65,2.63v.02s-.02.02-.02.02l-1.6,2.75c-1.41,2.41-2.78,4.53-4.19,6.51-3.42,4.83-7,8.77-10.9,12.02-2.46,2-4.85,3.57-7.35,4.81-2.74,1.38-5.73,2.32-8.67,2.71-.89.12-1.82.19-2.77.19-2.02,0-4.09-.27-5.95-.78-1.28-.36-2.58-.87-3.82-1.49-1.19-.6-2.31-1.35-3.35-2.09-1.37-1.03-2.63-2.24-3.84-3.66.36.02.72.02,1.09.02,2.84,0,5.82-.43,9.1-1.3,10.51-2.76,16.8-11.25,22.36-18.75.31-.43.64-.86.94-1.27l4.28-5.69,2.69-3.42c1.1-1.26,2.04-2.3,3.05-3.29,2.31-2.24,4.82-4.15,7.46-5.69,2.78-1.63,5.88-2.79,9.01-3.4,1.51-.3,3.05-.44,4.61-.44,4.52,0,9.02,1.24,12.62,3.5,1.18.74,2.28,1.55,3.29,2.4-.76-.04-1.51-.06-2.25-.06Z" />
    <path d="M36.24,51.04s-.01,0-.02,0c-.85,0-1.58-.56-1.82-1.36l-1.5-4.87c-.19-.61-.67-1.1-1.27-1.29l-4.85-1.57c-.8-.26-1.34-1-1.34-1.84,0-.85.55-1.58,1.36-1.83l4.87-1.5c.61-.19,1.1-.67,1.3-1.28l1.57-4.85c.26-.8,1-1.33,1.84-1.33,0,0,0,0,.01,0,.84,0,1.58.55,1.83,1.35l1.5,4.88c.18.61.66,1.09,1.28,1.29l4.85,1.57c.8.26,1.34,1,1.33,1.85,0,.85-.55,1.58-1.36,1.83h0s-4.87,1.5-4.87,1.5c-.61.19-1.1.66-1.29,1.27l-1.57,4.86c-.26.8-1,1.33-1.84,1.33ZM36.71,48.97h0s0,0,0,0ZM28.99,40.12l3.38,1.1c1.35.43,2.42,1.51,2.84,2.88l1.05,3.41,1.1-3.39c.43-1.35,1.51-2.41,2.88-2.83l3.4-1.05-3.39-1.09c-1.37-.45-2.43-1.53-2.84-2.89l-1.04-3.4-1.1,3.39c-.44,1.36-1.52,2.41-2.89,2.83l-3.4,1.04ZM27.52,39.65h0s0,0,0,0Z" />
    <path d="M64,128C28.71,128,0,99.29,0,64S28.71,0,64,0s64,28.71,64,64-28.71,64-64,64ZM64,4.03C30.93,4.03,4.03,30.93,4.03,64s26.9,59.97,59.97,59.97,59.97-26.9,59.97-59.97S97.07,4.03,64,4.03Z" />
  </svg>
)

export const USDC = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" fill="currentColor" {...props}>
    <path d="M87.4,76.41c0-12.41-7.45-16.67-22.34-18.44-10.64-1.42-12.77-4.26-12.77-9.22s3.55-8.16,10.64-8.16c6.38,0,9.93,2.13,11.7,7.45.35,1.06,1.42,1.77,2.48,1.77h5.67c1.42,0,2.48-1.06,2.48-2.48v-.35c-1.42-7.8-7.8-13.83-15.96-14.54v-8.51c0-1.42-1.06-2.48-2.84-2.84h-5.32c-1.42,0-2.48,1.06-2.84,2.84v8.16c-10.64,1.42-17.37,8.51-17.37,17.38,0,11.7,7.09,16.31,21.98,18.08,9.93,1.77,13.12,3.9,13.12,9.57s-4.96,9.57-11.7,9.57c-9.22,0-12.41-3.9-13.48-9.22-.35-1.42-1.42-2.13-2.48-2.13h-6.03c-1.42,0-2.48,1.06-2.48,2.48v.35c1.42,8.86,7.09,15.25,18.79,17.02v8.51c0,1.42,1.06,2.48,2.84,2.84h5.32c1.42,0,2.48-1.06,2.84-2.84v-8.51c10.64-1.77,17.73-9.22,17.73-18.79h0Z" />
    <path d="M45.92,113.64C18.26,103.71,4.07,72.87,14.36,45.56c5.32-14.89,17.02-26.24,31.56-31.56,1.42-.71,2.13-1.77,2.13-3.55v-4.96c0-1.42-.71-2.48-2.13-2.84-.35,0-1.06,0-1.42.35C10.81,13.65-7.63,49.46,3.01,83.15c6.38,19.86,21.63,35.1,41.49,41.49,1.42.71,2.84,0,3.19-1.42.35-.35.35-.71.35-1.42v-4.96c0-1.06-1.06-2.48-2.13-3.19ZM83.5,3.01c-1.42-.71-2.84,0-3.19,1.42-.35.35-.35.71-.35,1.42v4.96c0,1.42,1.06,2.84,2.13,3.55,27.66,9.93,41.84,40.78,31.56,68.08-5.32,14.89-17.02,26.24-31.56,31.56-1.42.71-2.13,1.77-2.13,3.55v4.96c0,1.42.71,2.48,2.13,2.84.35,0,1.06,0,1.42-.35,33.69-10.64,52.13-46.45,41.49-80.14-6.38-20.21-21.99-35.46-41.49-41.84h0Z" />
  </svg>
)

export const ThreeDots = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" {...props}>
    <circle cx="2" cy="8" r="2" />
    <circle cx="14" cy="8" r="2" />
    <circle cx="8" cy="8" r="2" />
  </svg>
)
